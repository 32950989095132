



































































































































































































import { Shop } from '@/api'
import { FormHelper } from '@/mixins'
import { GameModule, ShopModule } from '@/store/modules'
import { Component, Mixins, Watch } from 'vue-property-decorator'

enum WheelState {
  READY = 'Spin',
  SPINNING = 'Spinning...',
  SPINNED = 'Spin',
}

type WheelItem = {
  id: string
  iconId: number
  isJackpot: boolean
  isReward: boolean
  itemVnum: number
  name: string
  quantity: number
}

@Component({
  components: {
    ShopItemModal: () => import('@/components/Shop/ShopItemModal.vue'),
  }
})
export default class FortuneWheel extends Mixins(FormHelper) {
  @GameModule.State('characters') charactersFromAPI
  @ShopModule.State('initialShopItems') shopItems
  @ShopModule.Action getShopItems

  freeze = false
  rolling = false
  buttonEnabled = true
  availableJackpots = [] as WheelItem[]
  availableItems = [] as WheelItem[]
  availableSpins: Nullable<number> = null
  pity = null
  jackpotId: Nullable<number> = null
  characterId: Nullable<number> = null
  characters = [] as any[]
  showItems = false
  animationDuration = 5
  offsetAnimationDuration = 1000
  spinCounter = 0
  spinResultIndex: Nullable<number> = null
  wheelResult: Nullable<WheelItem> = null
  wheelState: WheelState = WheelState.READY
  wheelItems = [] as any[]
  positions = [
    ['25px', '181px'],
    ['40px', '240px'],
    ['73px', '292px'],
    ['123px', '328px'],
    ['181px', '340px'],
    ['243px', '328px'],
    ['295px', '295px'],
    ['330px', '240px'],
    ['340px', '180px'],
    ['330px', '120px'],
    ['295px', '70px'],
    ['243px', '38px'],
    ['181px', '22px'],
    ['123px', '38px'],
    ['73px', '73px'],
    ['40px', '122px'],
  ]

  @Watch('characterId')
  async onCharacterIdChange() {
    await this.resetWheel()
  }

  get getCharacterName() {
    return this.characters.find(s => s.id == this.characterId).name
  }

  get isButtonClickable() {
    return this.enabled
        && this.availableSpins != null
        && this.availableSpins > 0
  }

  get enabled() {
    return this.jackpotId != null
        && this.characterId != null
        && this.wheelState != WheelState.SPINNING
  }

  get isWheelReady() {
    return this.wheelState === WheelState.READY
        && this.characterId != null
  }

  get isWheelSpinning() {
    return this.wheelState === WheelState.SPINNING
  }

  get isWheelSpinned() {
    return this.wheelState === WheelState.SPINNED
        && this.characterId != null
  }

  async mounted() {
    try {
      await this.setupWheelData()
      await this.setupCharacters()
    } catch (e) {
      console.error('Cannot setup wheel')
    }
  }

  async setupWheelData() {
    const { result } = await Shop.getFortuneWheelItems()

    this.availableJackpots = result.jackpots
    this.setJackpotId(result.jackpots[0].itemVnum)
    this.availableItems = result.items
  }

  async setupCharacters() {
    const { result } = await Shop.getFortuneWheelSpins()
    const characters = this.charactersFromAPI.map(c => {
      const fortuneWheelCharacterData = result.find(e => e.characterId === c.id)

      return {
        id: c.id,
        name: c.name,
        availableSpins: fortuneWheelCharacterData?.remainingSpins,
        pity: fortuneWheelCharacterData?.spinsBeforePity
      }
    })

    this.characters = characters

    /* Refresh spins in case user consumes a FW Ticket in game */
    if (this.characterId) {
      const { availableSpins, pity } = this.characters.find(e => e.id === this.characterId)

      this.availableSpins = availableSpins
      this.pity = pity
    }
  }

  async spinWheel() {
    if (this.rolling || !this.enabled || !this.availableSpins || !this.buttonEnabled)
      return

    this.buttonEnabled = false

    if (this.wheelState === WheelState.SPINNED)
      await this.resetWheel()

    this.wheelState = WheelState.SPINNING

    const { result } = await Shop.spinFortuneWheel({
      itemVnum: this.jackpotId!,
      characterId: this.characterId!
    })

    this.wheelItems = result?.items.map((item, index) => {
      const [top, left] = this.positions[index]

      return {...item, top, left}
    })

    this.showItems = true

    setTimeout(() => {
      this.rolling = true
      const spinResultIndex = this.wheelItems.findIndex(e => e.isReward)
      const selectedItem = document.getElementById('wheel-selected-field')

      selectedItem!.style.opacity = '1'
      selectedItem!.style.transition = `transform ${this.animationDuration}s cubic-bezier(.49, .99, .49, .99)`
      selectedItem!.style.transform = `rotate(${2520 + 22.5 * spinResultIndex}deg)`
      this.rolling = false
    }, this.offsetAnimationDuration)

    setTimeout(() => {
      this.wheelResult = this.wheelItems.find(e => e.isReward)
      this.spinResultIndex = this.wheelItems.findIndex(e => e.isReward)
      this.spinCounter++
      this.wheelState = WheelState.SPINNED
      this.buttonEnabled = true
      this.availableSpins! -= 1
    }, 1000 * this.animationDuration + this.offsetAnimationDuration)
  }

  async onSpinClick() {
    if (this.wheelState === WheelState.SPINNING) return

    if ([WheelState.READY, WheelState.SPINNED].includes(this.wheelState))
      await this.spinWheel()
  }

  async resetWheel() {
    const selectedItem = document.getElementById('wheel-selected-field')

    selectedItem!.style.opacity = '0'
    selectedItem!.style.removeProperty('transition')
    selectedItem!.style.transform = `rotate(0deg)`
    this.showItems = false
    this.wheelResult = null
    this.spinResultIndex = null
    await this.setupCharacters()

    const { availableSpins, pity } = this.characters.find(e => e.id === this.characterId)

    this.availableSpins = availableSpins
    this.pity = pity
  }

  setJackpotId(id) {
    this.jackpotId = id
  }

  checkResult(index) {
    return this.spinResultIndex === index
  }

  async openShopModal() {
    let fortuneWheelTicketShopItem = this.shopItems.find(e => e.id === 'OL-FORTUNE-WHEEL-TICKET')

    if (!fortuneWheelTicketShopItem) {
      const shopItems = await this.getShopItems()

      fortuneWheelTicketShopItem = shopItems.find(e => e.id === 'OL-FORTUNE-WHEEL-TICKET')
    }

    this.$root.$emit('openShopItemModal', fortuneWheelTicketShopItem)
  }
}
